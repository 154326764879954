import { ReactNode } from 'react';
import { defaultStyles } from '@/utils/defaultStyles';

import Footer from './Footer';
import Navbar from './Navbar';
import { BasicModal } from './BasicModal';

type AppWrapperType = {
  children: ReactNode;
};

const AppWrapper = ({ children }: AppWrapperType) => {
  return (
    <>
      <main>{children}</main>
    </>
  );
};

export default AppWrapper;
