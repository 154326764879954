import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { BestOptionResponse, CartItem, VignetteResponse } from '@/utils/apiInterfaces';
import axios from 'axios';
import { setCart } from '@/utils/dataStorage';

interface VignetteContextType {
  selectedVignette: VignetteResponse | undefined;
  startDate: string;
  endDate: string;
  plates: string;
  vignettes: BestOptionResponse | null;
  vehicleType: number | undefined;
  email: string;
  number: string;
  selecteVignette: string | null;
  secondPlates: string;
  persons: string;
  fuelType?: number | null;
  selectedMultiple?: number[] | null;
  setSelectedMultiple: (item: number[] | null) => void;

  vignetteTime: string;
  setVignetteTime: (time: string) => void;
  setVignetteData: (item: CartItem) => void;
  selectedCountryCode: string;
  setSelectedCountryCode: (countrycode: string) => void;
  setCartData: () => void;
  setMultipleCartData: (item: BestOptionResponse[]) => void;

  selectedCountry: number | null;
  selectedCountryName: string | null;
  selectedCar: string | null;
  setSelectedCountry: (country: number | null) => void;
  setSelectedCountryName: (country: string | null) => void;
  setSelectedCar: (car: string | null) => void;
  setFuelType: (fuel: number | null) => void;

  setPersons: (persons: string) => void;
  setSecondPlates: (seconPlates: string) => void;
  clearVignetteData: () => void;
  setSelecteVignette: (vignette: string | null) => void;
  setPlates: (plate: string) => void;
  setVignette: (vignette: VignetteResponse | undefined) => void;
  setVehicleType: (vehicle: number | undefined) => void;
  setVignettes: (vignette: BestOptionResponse) => void;
  multipleVignettes: BestOptionResponse[] | null;
  setMultipleVignettes: (viggnettes: BestOptionResponse[] | null) => void;
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
  setEmail: (email: string) => void;
  setNumber: (number: string) => void;
  updateVignettes: () => void;
}

// Domyślne wartości kontekstu
const VignetteContext = createContext<VignetteContextType | undefined>(undefined);

interface VignetteProviderProps {
  children: ReactNode;
}

// Dostawca kontekstu
export const VignetteProvider: React.FC<VignetteProviderProps> = ({ children }) => {
  const [startDate, setStartDate] = useState<string>(new Date().toISOString());
  const [endDate, setEndDate] = useState<string>(new Date().toISOString());
  const [vignettes, setVignettes] = useState<BestOptionResponse | null>(null);
  const [vehicleType, setVehicleType] = useState<number | undefined>();
  const [plates, setPlates] = useState<string>('');
  const [selectedVignette, setSelectedVignette] = useState<VignetteResponse | undefined>();
  const [email, setEmail] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [selecteVignette, setSelecteVignette] = useState<string | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<number | null>(null);
  const [selectedCountryName, setSelectedCountryName] = useState<string | null>(null);
  const [selectedCar, setSelectedCar] = useState<string | null>(null);
  const [secondPlates, setSecondPlates] = useState<string>('');
  const [fuelType, setFuelType] = useState<number | null>(1);
  const [persons, setPersons] = useState<string>('');
  const [selectedMultiple, setSelectedMultiple] = useState<number[] | null>(null);
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>("+48");
  const [vignetteTime, setVignetteTime] = useState<string>('');
  const [multipleVignettes, setMultipleVignettes] = useState<BestOptionResponse[] | null>(null);

  const [country, setCountry] = useState<string>('');

  const clearVignetteData = () => {
    setStartDate(new Date().toISOString());
    setEndDate(new Date().toISOString());
    setPlates('');
    setSecondPlates('');
    setVignette(undefined);
    setSelecteVignette(null);
    setEmail('');
    setNumber('');
    setSelectedCountryCode("+48");
    setVehicleType(undefined);
    setSelectedCountry(null);
    setSelectedCountryName(null);
    setSelectedCar(null);
    setFuelType(1);
    setVignetteTime('');
    setPersons('');
    setSelectedMultiple(null);
    setMultipleVignettes(null);
  };

  const setVignetteData = (item: CartItem) => {
    setStartDate(item.startDate);
    setEndDate(item.endDate);
    setPlates(item.plates);
    setSecondPlates(item.plates);
    setVignette(undefined);
    setSelecteVignette(null);
    setEmail(item.email);
    setNumber(item.number);
    setSelectedMultiple(null);
    setMultipleVignettes(null);
    setSelectedCountryCode(item.selectedCountryCode);
    setVehicleType(item.vehicleType);
    setSelectedCountry(item.country === 'Austria' ? 1 : item.country === 'Czechy' ? 2 : item.country === 'Szwajcaria' ? 3 : item.country === 'Węgry' ? 4 : item.country === 'Rumunia' ? 5 : item.country === 'Słowacja' ? 6 : item.country === 'Bułgaria' ? 7 : 8);
    setSelectedCountryName(item.country);  
    setFuelType(item.fuelType);
    setPersons(item.persons);
  };

  const setCartData = () => {
    const newCartItem: CartItem = {
        vignetteId: selectedVignette?.vignetteId || 0,
        period: selectedVignette?.period || 0,
        price: selectedVignette?.price || '',
        startDate: selectedVignette?.startDate || '',
        endDate: selectedVignette?.endDate || '',
        vehicleType: vehicleType || 1,
        fuelType: fuelType || 1,
        country: countryMapping[selectedCountry || 0],
        plates: plates,
        selectedCountryCode: selectedCountryCode,
        persons: persons,
        number: number,
        email: email,
        vignetteTime: vignetteTime,
        currency: selectedVignette?.currency || '',
        basePrice: selectedVignette?.basePrice || '',
    };

    const currentCart = JSON.parse(localStorage.getItem("cart") || "[]") as CartItem[];

    const updatedCart = [...currentCart, newCartItem];

    setCart(updatedCart);
};

const setMultipleCartData = (bestResponses: BestOptionResponse[]) => {
  const currentCart = JSON.parse(localStorage.getItem("cart") || "[]") as CartItem[];

  const updatedCart = [...currentCart];

  bestResponses.forEach((response) => {
      const { realVignette, recommendVignette } = response;

      if (recommendVignette) {
        const newCartItem: CartItem = {
          vignetteId: recommendVignette.vignetteId,
          period: recommendVignette.period,
          price: recommendVignette.price,
          startDate: recommendVignette.startDate,
          endDate: recommendVignette.endDate,
          vehicleType: vehicleType || 1,
          fuelType: fuelType || 1,
          country: countryMapping[recommendVignette.countryId] || '',
          plates: plates,
          selectedCountryCode: selectedCountryCode,
          persons: persons,
          number: number,
          email: email,
          vignetteTime: vignetteTime,
          currency: recommendVignette.currency,
          basePrice: recommendVignette.basePrice,
        };
        updatedCart.push(newCartItem);
      }
    });

    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const countryMapping: { [key: number]: string } = {
    1: "Austria",
    2: "Czechy",
    3: "Szwajcaria",
    4: "Węgry",
    5: "Rumunia",
    6: "Słowacja",
    7: "Bułgaria",
    8: "Słowenia"
  };

  const setVignette = (data: any) => {
    setSelectedVignette(data);
    // if((plates != null && persons != null && email != null) || (plates != '' && persons != '' && email != ''))
    // {
    //   setCart({...data, vehicleType, country: countryMapping[selectedCountry === null ? 0 : selectedCountry], plates, persons, number, email});
    // }
  }

  const updateVignettes = async () => {
    let cId = [0];

    try {
      const response = await axios.post("/Vignette/calculate-best", {
        countryIds: cId,
        routeId: 0,
        fuelTypeId: fuelType,
        vehicleType: vehicleType,
        startDate: startDate.split("T")[0] + "T00:00:00.000Z",
        endDate: endDate.split("T")[0] + "T00:00:00.000Z"
      });
      setVignettes(response.data);
    } catch (error) {
      console.error("Błąd podczas aktualizacji winiety:", error);
    }
  };

  return (
    <VignetteContext.Provider 
      value={{ 
        plates, 
        setPlates, 
        startDate, 
        selectedVignette, 
        setVignette, 
        endDate, 
        setStartDate, 
        setEndDate, 
        vignettes, 
        setVignettes, 
        vehicleType, 
        setVehicleType,
        email,
        setSelectedCountryCode,
        selectedCountryCode,
        setEmail,
        number,
        secondPlates,
        persons,
        setFuelType,
        fuelType,
        setPersons,
        setNumber,
        setSecondPlates,
        selecteVignette,
        setSelecteVignette,
        clearVignetteData,
        selectedCountry,
        selectedCountryName,
        selectedCar,
        setSelectedCountry,
        setSelectedCountryName,
        setSelectedCar,
        updateVignettes,
        setCartData,
        setVignetteTime,
        setVignetteData,
        vignetteTime,
        selectedMultiple,
        setSelectedMultiple,
        multipleVignettes,
        setMultipleVignettes,
        setMultipleCartData
      }}
    >
      {children}
    </VignetteContext.Provider>
  );
};

// Hook do korzystania z kontekstu
export const useVignetteContext = (): VignetteContextType => {
  const context = useContext(VignetteContext);
  if (!context) {
    throw new Error('useVignetteContext must be used within a VignetteProvider');
  }
  return context;
};
