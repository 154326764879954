import type { AppProps } from 'next/app';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/globals.css';
import '../../i18n';
import AppWrapper from '../components/AppWrapper';
import { VignetteProvider } from '@/context/VignetteContext';
import { useEffect, useRef, useState } from 'react';
import { logout } from '@/utils/dataStorage';
import router, { useRouter } from 'next/router';
import axios from "@/axiosConfig";
import { appWithTranslation, i18n, useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetStaticProps } from 'next';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const translations = await serverSideTranslations(locale || 'pl', ['common']);
  //console.log('Tłumaczenia na serwerze:', JSON.stringify(translations, null, 2));

  return {
    props: {
      ...translations,
    },
  };
};


function App({ Component, pageProps }: AppProps) {
  const [isReady, setIsReady] = useState<boolean>(false);
    const { i18n } = useTranslation('common');
    useEffect(()=>{
      setIsReady(true);
  
    }, [i18n.isInitialized]);
  
    if (!i18n.isInitialized) {
      return null; // Możesz wyświetlić spinner lub placeholder
    }
  // useEffect(() => {
  //   const { language } = pageProps;

  //   // Ustaw język na podstawie wartości przekazanej z serwera
  //   if (language) {
  //     i18n.changeLanguage(language);
  //   }
  // }, [pageProps]);

  useEffect(() => {
    let lang: string = localStorage.getItem('i18nextLng') || 'pl'; // Fallback to 'pl' if null or empty
    //console.log(lang);
    i18n?.changeLanguage(lang); // Set default language
  }, []);
  

  useEffect(() => {
    const token = localStorage.getItem("token");
    //console.log(token)
    const checkToken = async () => {
      try {
        const response = await axios.post(`/auth/validate-token`, token);
      } 
      catch (error) {
          toast.error('Sesja wygasła, zaloguj się ponownie');
          localStorage.removeItem("token");
          localStorage.removeItem("username");
          localStorage.removeItem("userType");
          router.reload();
          //console.log(error);
      }
    };

    if(token != null)
    {
      checkToken();
    }
  }, []); 

  return (
    isReady ?
    (
      <>
      <VignetteProvider>
        <AppWrapper>
          <Component {...pageProps} />
        </AppWrapper>
        <ToastContainer 
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        />
        </VignetteProvider>
      </>
    )
    :
    null
  );
}


export default appWithTranslation(App);