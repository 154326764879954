import { CartItem, LoginResponse, VignetteResponse } from "./apiInterfaces";

export const saveLoginData = (data: LoginResponse) => {
    localStorage.setItem("token", data.token);
    localStorage.setItem("username", data.username);
    localStorage.setItem("userType", data.userType)
}  

export const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("userType");
    location.replace('/');
}

export const isLoggedIn = () => {
    if (localStorage.getItem("token"))
        return true;
    return false;
}

export const setCart = (data: CartItem[]) => {
    localStorage.setItem("cart", JSON.stringify(data));
}

export const setImg = (path: string) => {
    localStorage.setItem("path", path);
}

export const getCart = () => localStorage.getItem("cart");

export const clearCart = () => localStorage.removeItem("cart");

export const getUserName = () => localStorage.getItem("username");

export const getImg = () => localStorage.getItem("path");

export const getUserToken = () => localStorage.getItem("token");

export const setLanguage = (value: string) => localStorage.setItem("language", value);

export const getLanguage = () => localStorage.getItem("language");
